<template>
  <div class="bg">
    <div class="main-box" v-loading="loading">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>产品展示</div>
        <div class="bottom-line"></div>
      </div>
      <div class="product-box">
        <div class="base-box">
          <el-row class="slideShow">
            <el-carousel class="photo" :span="24" ref="cardShow" height="100%">
              <el-carousel-item
                v-if="index<5"
                v-for="(item,index) in productInfo.productImage"
                :key="index"
              >
                <img class="product-img" :src="item" alt="">
              </el-carousel-item>
              <!--            <img class="product-img" :src="productInfo.productImage" alt=""/>-->
            </el-carousel>
          </el-row>
        </div>
        <div class="base-box">
          <div class="product-title" :title="productInfo.productName">产品名称：{{ productInfo.productName }}</div>
          <div class="bottom-line"></div>
          <div class="product-msg">
            <div class="icon-text">
              <img src="@/assets/index/query/home.png" alt="">
              <span>企业名称</span>
            </div>
            <div class="text">
              {{ productInfo.companyName }}
            </div>
            <div class="icon-text">
              <img src="@/assets/index/query/base.png" alt="">
              <span>基地</span>
            </div>
            <div class="text">
              {{ productInfo.productionBaseName }}
            </div>
            <div class="icon-text">
              <img src="@/assets/index/query/certificate.png" alt="">
              <span>证书编号</span>
            </div>
            <div class="text">{{ productInfo.certificateNumber }}</div>
          </div>
        </div>
      </div>
      <div class="title">
        <div>认证证书</div>
        <div class="bottom-line"></div>
      </div>
      <div class="certificate-img">
        <img :src="productInfo.certificateImagePath" alt="" width="100%" height="100%">
      </div>
      <div class="title">
        <div>供应平台</div>
        <div class="bottom-line"></div>
      </div>
      <div class="apply-box">
        <div class="btn-box">
          <img src="@/assets/index/query/dy.png" alt=""/>
          <span>抖音商城</span>
        </div>
        <div class="btn-box">
          <img src="@/assets/index/query/tb.png" alt=""/>
          <span>淘宝商城</span>
        </div>
        <div class="btn-box">
          <img src="@/assets/index/query/qdm.png" alt=""/>
        </div>
        <div class="btn-box">
          <img src="@/assets/index/query/hmxs.png" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'
  import {
    productList
  } from '@/api/mock/homeIndex'
  import { getCertificateById } from './api/api'

  export default {
    name: 'QueryDetail',
    components: { Breadcurmb },
    data() {
      return {
        productList: productList,
        productInfo: {},
        loading: false,
        menuList: [
          {
            name: '认证查询',
            path: '/bayareacertification/query'
          },
          {
            name: '认证详情',
            path: ''
          }
        ]
      }
    },
    created() {
      this.getNewsDetail()
    },
    methods: {
      getNewsDetail() {
        this.loading = true
        getCertificateById({ id: this.$route.query.id }).then(res => {
          this.productInfo = Object.assign(res.data, {
            productImage: res.data.productImage ? res.data.productImage.split(',') : ''
          })
          this.addWarker(this.productInfo.certificateImagePath, '广东粤港澳大湾区认证促进中心')
          this.loading = false
        }).finally(() => {
          this.loading = false
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      // 添加水印
      addWarker(url, warkerName) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = url
        image.setAttribute('crossOrigin', 'Anonymous')
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0)

          // 绘制水印 旋转45度
          ctx.font = '40px Arial'
          ctx.rotate(270)
          ctx.fillStyle = 'rgba(181,177,177,1)' // 白色半透明
          for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 2; j++) {
              ctx.fillText(warkerName, ((canvas.width / 2) + 30) * j, (canvas.height / 4) * i)
            }
          }

          // 将canvas转换为图片源
          this.productInfo.certificateImagePath = canvas.toDataURL('image/png')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  background: url("~@/assets/index/query/query-bg.png") no-repeat;
}

.main-box {
  width: 1000px;
  margin: 85px auto 60px;

  .title {
    margin: 33px 0 20px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .product-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 400px;
  }

  .product-title {
    width: 100%;
    font-size: 24px;
    color: @primary-color;
    font-weight: bold;
    margin: 20px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    -webkit-line-clamp: 2; /* 限制显示两行 */
  }

  .product-msg {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    margin-top: 10px;

    .icon-text {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        height: 35px;
        margin-right: 8px;
      }

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .text {
      margin: 0 0 23px 43px;
    }
  }

  .base-box {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .bottom-line {
      width: 100%;
      border-bottom: 1px solid #cccccc;
    }

    .slideShow {
      position: relative;
      width: calc(100% - 50px);
      height: 100%;
      //margin-bottom: 13px;

      .photo {
        height: 100%;

        img {
          width: 100%;
          height: calc(100% - 50px);
        }
      }

      .photo:hover {
        cursor: pointer;
      }
    }
  }

  .certificate-img {
    width: 632px;
    margin: 0 auto;
  }

  .product-img {
    width: 420px;
    height: 100%;
    border-radius: 20px;
  }

  .apply-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 100px;
      font-size: 20px;
      font-weight: bold;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(187, 187, 187, 0.44);

      span {
        margin-left: 5px;
      }
    }
  }
}

/deep/ .el-carousel__button {
  height: 6px;
  background-color: @primary-color;
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 45px;
}
</style>
